import React from "react"
import theme from "theme"
import {
  Theme,
  Text,
  Box,
  Section,
  Strong,
  Image,
  Link,
} from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"index"} />
      <Helmet>
        <title>Home | Legal Pathways Firm</title>
        <meta
          name={"description"}
          content={"Guiding You Through the Legal Jungle"}
        />
        <meta property={"og:title"} content={"Home | Legal Pathways Firm"} />
        <meta
          property={"og:description"}
          content={"Guiding You Through the Legal Jungle"}
        />
        <meta
          property={"og:image"}
          content={"https://fivaradrak.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://fivaradrak.com/img/note-icon.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://fivaradrak.com/img/note-icon.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://fivaradrak.com/img/note-icon.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://fivaradrak.com/img/note-icon.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://fivaradrak.com/img/note-icon.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://fivaradrak.com/img/note-icon.png"}
        />
        <meta
          name={"msapplication-TileColor"}
          content={"https://fivaradrak.com/img/note-icon.png"}
        />
      </Helmet>
      <Components.Header />
      <Section
        background="linear-gradient(0deg,rgba(4, 8, 12, 0.6) 0%,rgba(4, 8, 12, 0.6) 100%),--color-darkL2 url(https://fivaradrak.com/img/1.jpg) center/cover"
        padding="60px 0"
        sm-padding="40px 0"
        color="--light"
        font="--base"
        min-height="700px"
      >
        <Box margin="-16px -16px -16px -16px" display="flex">
          <Box
            display="flex"
            padding="16px 16px 16px 16px"
            width="75%"
            md-width="100%"
          >
            <Box display="flex" flex-direction="column">
              <Text
                color="--lightD2"
                letter-spacing="1px"
                text-transform="uppercase"
                margin="0"
              >
                Your Journey to Justice Begins Here
              </Text>
              <Text
                as="h1"
                font="--headline1"
                md-font="--headline2"
                margin="100px 0"
              >
                Legal Pathways
              </Text>
              <Text color="--lightD2" letter-spacing="1px" margin="0">
                Welcome! At Legal Pathways Firm, we're dedicated to offering you
                comprehensive legal solutions tailored to your needs. Our team
                understands the intricacies of the legal system and is here to
                navigate you through every step, ensuring clarity and peace of
                mind. Whether you're facing a personal legal challenge or
                seeking advice for your business, we're here to guide you to a
                successful outcome.
              </Text>
            </Box>
          </Box>
        </Box>
      </Section>
      <Section
        padding="60px 0 90px 0"
        sm-padding="40px 0"
        sm-min-height="auto"
        display="flex"
        quarkly-title="Advantages/Features-11"
      >
        <Override
          slot="SectionContent"
          flex-direction="row"
          flex-wrap="wrap"
          grid-gap="5%"
          sm-min-width="280px"
        />
        <Box
          display="flex"
          width="100%"
          flex-direction="column"
          justify-content="center"
          align-items="flex-start"
          lg-width="100%"
          lg-align-items="flex-start"
          lg-margin="0px 0px 60px 0px"
          sm-margin="0px 0px 40px 0px"
          sm-padding="0px 0px 0px 0px"
          lg-flex-direction="row"
          lg-flex-wrap="wrap"
          sm-width="100%"
          md-margin="0px 0px 30px 0px"
          margin="0px 0px 64px 0px"
          lg-justify-content="flex-start"
        >
          <Text
            as="h1"
            margin="0px"
            font="--headline2"
            color="--dark"
            width="100%"
            md-width="100%"
            md-margin="0px 0px 32px 0px"
            md-padding="0px 0px 0px 0px"
            sm-font="normal 600 40px/1.2 --fontFamily-sans"
            text-align="left"
          >
            Why Choose Us?
          </Text>
          <Text
            as="p"
            margin="20px 0 0 0"
            font="--lead"
            font-size="20px"
            font-weight="300"
            color="--dark"
            width="60%"
            md-width="100%"
            md-margin="0px 0px 0px 0px"
            md-padding="0px 0px 0px 0px"
            sm-font="--base"
            text-align="left"
            lg-width="100%"
            lg-max-width="720px"
          >
            At Legal Pathways, we pride ourselves on our distinctive approach to
            legal services. Our commitment is not just to resolve your legal
            matters but to do so with unwavering support, transparency, and
            innovation. Here's why you should consider us:
          </Text>
        </Box>
        <Box
          width="100%"
          display="grid"
          grid-template-columns="repeat(3, 1fr)"
          grid-gap="32px"
          lg-grid-template-columns="1fr"
          lg-grid-gap="48px"
        >
          <Box
            display="flex"
            flex-direction="column"
            align-items="flex-start"
            justify-content="space-between"
          >
            <Box
              align-items="flex-start"
              display="flex"
              flex-direction="column"
              lg-max-width="720px"
            >
              <Text font="--lead" color="--dark" margin="0px 0px 6px 0px">
                <Strong>Personalized Attention</Strong>
              </Text>
              <Text margin="0px 0px 24px 0px" font="--base" color="--darkL2">
                Every case is unique, and we treat it as such, offering tailored
                advice and strategies.
              </Text>
            </Box>
          </Box>
          <Box
            display="flex"
            flex-direction="column"
            align-items="flex-start"
            justify-content="space-between"
          >
            <Box
              align-items="flex-start"
              display="flex"
              flex-direction="column"
              lg-max-width="720px"
            >
              <Text font="--lead" color="--dark" margin="0px 0px 6px 0px">
                <Strong>Expertise Across the Board</Strong>
              </Text>
              <Text margin="0px 0px 24px 0px" font="--base" color="--darkL2">
                Our team has a wide range of specialties, ensuring you receive
                competent guidance, no matter the complexity.
              </Text>
            </Box>
          </Box>
          <Box
            display="flex"
            flex-direction="column"
            align-items="flex-start"
            justify-content="space-between"
          >
            <Box
              align-items="flex-start"
              display="flex"
              flex-direction="column"
              lg-max-width="720px"
            >
              <Text font="--lead" color="--dark" margin="0px 0px 6px 0px">
                <Strong>Transparent Communication</Strong>
              </Text>
              <Text margin="0px 0px 24px 0px" font="--base" color="--darkL2">
                We believe in keeping you fully informed, demystifying legal
                jargon so you can make decisions with confidence.
              </Text>
            </Box>
          </Box>
        </Box>
      </Section>
      <Section padding="80px 0 80px 0">
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          display="flex"
          align-items="center"
          flex-direction="column"
          justify-content="center"
          margin="0px 0px 64px 0px"
          width="100%"
          sm-margin="0px 0px 30px 0px"
          padding="0px 200px 0px 200px"
          lg-padding="0px 0px 0px 0px"
        >
          <Text
            margin="0px 0px 16px 0px"
            font="--headline2"
            text-align="center"
            sm-font='normal 700 42px/1.2 "Source Sans Pro", sans-serif'
          >
            Our Services
          </Text>
          <Text
            margin="0px 0px 0px 0px"
            color="--darkL2"
            text-align="center"
            font="--lead"
          >
            At Legal Pathways Firm, we understand that each client brings a
            unique set of challenges and needs. Our services are designed to
            offer comprehensive support, guiding you through every aspect of the
            legal process with expertise and empathy. Below, you'll find an
            overview of the specialized services we provide, each tailored to
            navigate specific legal landscapes.
          </Text>
        </Box>
        <Box
          display="grid"
          flex-wrap="wrap"
          width="100%"
          align-items="center"
          justify-content="center"
          grid-template-columns="repeat(3, 1fr)"
          grid-gap="30px"
          md-grid-template-columns="1fr"
        >
          <Box
            width="100%"
            display="flex"
            padding="7px 7px 40px 7px"
            border-radius="24px"
            align-items="center"
            flex-direction="column"
            border-width="1px"
            border-style="solid"
            border-color="--color-lightD2"
            justify-content="center"
          >
            <Image
              src="https://fivaradrak.com/img/2.jpg"
              border-radius="16px"
              margin="0px 0px 32px 0px"
              max-width="100%"
              md-width="100%"
              width="100%"
              height="278px"
              object-fit="cover"
            />
            <Box
              display="flex"
              flex-direction="column"
              padding="0px 30px 0px 30px"
              align-items="flex-start"
              justify-content="flex-start"
            >
              <Text margin="0px 0px 8px 0px" font="--headline3">
                Family Law
              </Text>
              <Text margin="0px 0px 48px 0px" color="--darkL2" font="--base">
                • Divorce and Separation: Navigating the legalities of ending a
                relationship with compassion and clarity.
                <br />
                • Child Custody: Ensuring the best interests of your children
                are at the forefront.
                <br />
                • Property Division: Fair and equitable distribution of assets.
                <br />
                • Adoption: Legal guidance through the joyous journey of
                expanding your family.
                <br />• Domestic Adoptions: Navigating state regulations to
                welcome a new family member.
              </Text>
            </Box>
          </Box>
          <Box
            width="100%"
            display="flex"
            padding="7px 7px 40px 7px"
            border-radius="24px"
            align-items="center"
            flex-direction="column"
            border-width="1px"
            border-style="solid"
            border-color="--color-lightD2"
            justify-content="center"
            position="relative"
          >
            <Image
              src="https://fivaradrak.com/img/3.jpg"
              border-radius="16px"
              margin="0px 0px 32px 0px"
              max-width="100%"
              md-width="100%"
              max-height="278px"
              object-fit="cover"
              width="100%"
            />
            <Box
              display="flex"
              flex-direction="column"
              padding="0px 30px 0px 30px"
              align-items="flex-start"
              justify-content="flex-start"
            >
              <Text margin="0px 0px 8px 0px" font="--headline3">
                Corporate Law
              </Text>
              <Text margin="0px 0px 48px 0px" color="--darkL2" font="--base">
                Business Formation and Structuring: Building solid foundations
                for your business ventures.
                <br />
                • LLCs, Partnerships, and Corporations: Selecting the right
                structure for your business.
                <br />
                • Compliance and Governance: Ensuring your business operates
                within legal frameworks.
                <br />
                • Intellectual Property Protection: Safeguarding your
                innovations and creations.
                <br />
                • Trademarks and Patents: Securing your brand and inventions.
                <br />• Copyrights: Protecting your original works from
                unauthorized use.
              </Text>
            </Box>
          </Box>
          <Box
            width="100%"
            display="flex"
            padding="7px 7px 40px 7px"
            border-radius="24px"
            align-items="center"
            flex-direction="column"
            border-width="1px"
            border-style="solid"
            border-color="--color-lightD2"
            justify-content="center"
          >
            <Image
              src="https://fivaradrak.com/img/4.jpg"
              border-radius="16px"
              margin="0px 0px 32px 0px"
              max-width="100%"
              md-width="100%"
              max-height="278px"
              object-fit="cover"
              width="100%"
            />
            <Box
              display="flex"
              flex-direction="column"
              padding="0px 30px 0px 30px"
              align-items="flex-start"
              justify-content="flex-start"
            >
              <Text margin="0px 0px 8px 0px" font="--headline3">
                Real Estate Law
              </Text>
              <Text margin="0px 0px 48px 0px" color="--darkL2" font="--base">
                Property Transactions: Smooth navigation through buying,
                selling, or leasing property.
                <br />
                • Residential Real Estate: Ensuring the comfort and legality of
                your home purchase or sale.
                <br />
                • Commercial Leases: Tailoring agreements to suit your business
                needs.
                <br />
                • Landlord-Tenant Disputes: Balancing rights and
                responsibilities for peaceful resolutions.
                <br />
                • Eviction Proceedings: Navigating the legal process with
                fairness.
                <br />• Lease Negotiations: Advocating for terms that protect
                your interests.
              </Text>
            </Box>
          </Box>
        </Box>
      </Section>
      <Section
        padding="65px 0 65px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="Images-11"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          display="flex"
          width="50%"
          lg-width="100%"
          lg-align-items="center"
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          flex-direction="column"
          align-items="flex-start"
          lg-margin="0px 0px 0px 0px"
          sm-margin="0px 0px 0px 0px"
        >
          <Text
            sm-font="--headline3"
            margin="0px 0px 36px 0px"
            color="--darkL2"
            font="--headline2"
            lg-text-align="center"
            md-text-align="left"
          >
            Your Journey to Justice Begins Here
          </Text>
          <Text
            margin="0px 0px 0px 0px"
            color="--darkL2"
            font="--base"
            lg-text-align="center"
            sm-margin="0px 0px 30px 0px"
            md-text-align="left"
          >
            Ready to take the next step? We're here to help.
          </Text>
          <Link href="/contacts" color="#000000" margin="30px 0px 0px 0px">
            Contact Us{" "}
          </Link>
        </Box>
        <Box
          width="50%"
          lg-width="100%"
          margin="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          sm-padding="0px 0px 0px 0px"
          sm-margin="0px 0px 30px 0px"
          flex-direction="column"
          display="flex"
        >
          <Box
            width="100%"
            height="auto"
            padding="0px 0px 70% 0px"
            transition="transform 0.2s ease-in-out 0s"
            hover-transform="translateY(-10px)"
            overflow-x="hidden"
            overflow-y="hidden"
            position="relative"
            transform="translateY(0px)"
          >
            <Image
              position="absolute"
              width="100%"
              top="auto"
              left={0}
              src="https://fivaradrak.com/img/5.jpg"
              object-fit="cover"
              display="block"
              right={0}
              bottom="0px"
              min-height="100%"
            />
          </Box>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
